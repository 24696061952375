import React from 'react'
import propTypes from 'prop-types'

import './style.scss'

const Button = ({ handleClick, classMod, text, disabled = false }) => {
  const buttonState = disabled ? 'disabled' : 'enabled'

  return (
    <button
      className={`button button--${classMod}--${buttonState}`}
      onClick={!disabled ? handleClick : null}
    >
      {text}
    </button>
  )
}

Button.propTypes = {
  handleClick: propTypes.func.isRequired,
  classMod: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  disabled: propTypes.bool,
}

export default Button
