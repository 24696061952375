export const festivalData = [
  {
    slug: 'coachella',
    image: '/images/Coachella1.png',
    secondaryImg: '/images/Coachella2.png',
    title: 'coachella festival',
    subtitle:
      "are you even on instagram if you haven't posted a picture before the iconic ferris wheel on coachella?",
    description:
      "Two weekends, one opportunity to go. use your points and claim your tickets below. Let's go to Coachella!",
  },
  {
    slug: 'o-beach-ibiza',
    image: '/images/Obeach1.png',
    secondaryImg: '/images/Obeach2.png',
    title: 'o beach ibiza',
    subtitle:
      'You say poolparty? We say let’s go to ibiza! Claim your ticket below for a poolparty like you never experienced before.',
    description:
      'Grab your floatable flamingo, unicorn or donut - we’ll do the rest. Use your points and claim your tickets below!',
  },
  {
    slug: 'boiler-room',
    image: '/images/Boiler1.png',
    secondaryImg: '/images/Melkweg2.png',
    title: 'ra boiler room',
    subtitle:
      'We’ve all seen the sets on youtube. do you want to be that person next to the dj on the livestream? This is your chance!',
    description:
      'A dj-set, a dj, a crowd and a livestream. That’s all you need for a party with Resident Advisor’s Boiler Room. Get your tickets now while they’re boiling hot! ',
  },
  {
    slug: 'melkweg',
    image: '/images/Melkweg1.png',
    secondaryImg: '/images/Melkweg2.png',
    title: 'melkweg events',
    subtitle:
      'Pick one of many exciting melkweg events to attend in the heart of Amsterdam!',
    description:
      'Explore the musical universe with these tickets! Boldly go where no one has gone before.',
  },
  {
    slug: 'light-museum',
    image: '/images/Lightmuseum1.png',
    secondaryImg: '/images/Lightmuseum2.png',
    title: 'light museum',
    subtitle: 'Explore all aspects of luminescence in this bright museum',
    description:
      'Our visual world is made of light. In this museum you learn more obout this more than essential part of life',
  },
  {
    slug: 'rijksmuseum',
    image: '/images/Rijksmuseum1.png',
    secondaryImg: '/images/Rijksmuseum2.png',
    title: 'rijksmuseum',
    subtitle: 'Wanna see a real Rembrandt?',
    description:
      'Delve into the Dutch golden age and learn interesting stories you can use to charm people at parties!',
  },
]
