import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import AmountSelector from '../amount-selector'
import Banner from '../banner'
import Button from '../button'
import Header from '../header'
import Icon from '../icon'
import ProgressBar, { ProgressBarWithoutRows } from '../progress-bar'
import { festivalData } from './constants'
import './style.scss'

const TicketPopup = () => {
  const history = useHistory()

  const handleClick = (slug) => {
    history.push(slug)
  }

  return (
    <div className="details-block__popup">
      <p>You just got ticket(s) with your points!</p>
      <h3>Yay! Ticket = in the pocket! 🎉</h3>
      <p>
        We saved the ticket(s) to your account. We also sent the ticket(s) to
        your emailaddress
      </p>
      <div className="details-block__popup--btnsWrapper">
        <Button
          classMod="secondary"
          text="Home"
          handleClick={() => handleClick('/')}
        />
        <Button
          classMod="primary"
          text="Go to my tickets"
          handleClick={() => handleClick('/my-tickets')}
        />
      </div>
    </div>
  )
}

const DetailsBlock = ({ subPage, setSubPage }) => {
  const [popup, setPopup] = useState(false)
  const { id } = useParams()
  const detailData = festivalData.find((festival) => festival.slug === id)
  const { slug, image, secondaryImg, title, subtitle, description } = detailData

  if (subPage === 'tickets') {
    return (
      <div className="details-block">
        <Header small title="Get tickets!" />
        <div className="details-block__ticketsWrapper">
          <h4>Event 3</h4>
          <div className="details-block__ticket-amount">
            <AmountSelector minValue={0} maxValue={10} />
            <div className="details-block__ticket-amount__event">
              <h5>{title}</h5>
              <p>Last row tickets</p>
            </div>
          </div>
          <div className="details-block__points">
            <p>Points needed</p>
            <div className="details-block__points--fragment--1">
              <p>200</p>
              <Icon name="points-green" />
            </div>
          </div>
          <div className="details-block__points details-block__points--last">
            <p>Your total points</p>
            <div className="details-block__points--fragment--2">
              <p>200</p>
              <Icon name="points-blue" />
            </div>
          </div>
          <div className="details-block__ticketsBtnWrapper">
            <Button
              classMod="primary"
              text="Get tickets"
              handleClick={() => setPopup(true)}
            />
          </div>
        </div>
        {popup && <TicketPopup />}
      </div>
    )
  } else {
    return (
      <div className="details-block">
        <Banner points={200} image={image} title={title} />
        {slug === 'boiler-room' ? <ProgressBarWithoutRows /> : <ProgressBar />}
        <h5>{subtitle}</h5>
        <div className="details-block__subtitle">
          <img alt="festival" src={secondaryImg} />
          <p>{description}</p>
        </div>
        <div className="details-block__btnsWrapper">
          <div>
            <p>2 days entry</p>
            <Button
              classMod="primary"
              text="Get ticket"
              handleClick={() => setSubPage('tickets')}
            />
          </div>
          <div>
            <p>2 days VIP</p>
            <Button
              classMod="primary"
              text="Get ticket"
              handleClick={() => setSubPage('tickets')}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default DetailsBlock
