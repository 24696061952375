import React, { useState } from 'react'

import TopBar from '../layout/top-bar'
import Footer from '../layout/footer'
import Main from '../layout/main'
import DetailsBlock from '../components/details-block'

export default function DetailPage() {
  const [subPage, setSubPage] = useState('event')

  return (
    <>
      <TopBar />
      <Main>
        <section className="details-wrapper">
          <DetailsBlock subPage={subPage} setSubPage={setSubPage} />
        </section>
      </Main>
      <Footer />
    </>
  )
}
