import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import FooterItem from '../../components/footer-item'
import './style.scss'

const Footer = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  const footerProps = [
    { text: 'home', icon: 'home', slug: '/' },
    { text: 'categories', icon: 'compass', slug: '/categories' },
    { text: 'account', icon: 'user', slug: '/account' },
  ]

  const handleClick = (slug, e) => {
    e.preventDefault()
    history.push(slug)
  }

  const handleActiveFooter = (slug, pathname) => {
    if (slug === '/') {
      return slug === pathname && true
    } else if (slug === '/categories') {
      if (pathname.includes('events') || slug === pathname) {
        return true
      } else {
        return false
      }
    } else if (slug === '/account') {
      if (
        pathname === '/account' ||
        pathname === '/my-tickets' ||
        pathname === '/personal-information'
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  return (
    <footer className="footer">
      {footerProps.map(({ text, icon, slug }, i) => {
        return (
          <FooterItem
            key={i}
            text={text}
            icon={icon}
            slug={slug}
            i={i}
            handleClick={handleClick}
            active={handleActiveFooter(slug, pathname)}
          />
        )
      })}
    </footer>
  )
}

export default Footer
