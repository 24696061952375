import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import HomePage from './pages/index'
import CategoriesPage from './pages/categories'
import AccountPage from './pages/account'
import TicketsPage from './pages/mytickets'
import PersonalInformation from './pages/personalinformation'
import DetailPage from './pages/details'
import LasPage from './pages/las'
import VerficationPage from './pages/verification'
import SingupPage from './pages/signup'
import PrivateRoute from './lib/privateRoute'
import useFirebaseAuth from './lib/useFirebaseAuth'
import './styles/globals.scss'
import './styles/main.scss'


const App = () => {
  const {
    user,
    displayName,
    error,
    setError,
    email,
    password,
    handleChangeInput,
    signInWithEmailPassword,
    signUpWithEmailPassword,
    signInWithGoogle,
    signInWithFacebook,
    signOut,
  } = useFirebaseAuth()
  
  return (
    <div className="container">
      <Router>
        <PrivateRoute exact path="/" user={user}>
          <HomePage user={user} displayName={displayName} signOut={signOut} />
        </PrivateRoute>
        <PrivateRoute exact path="/categories" user={user}>
          <CategoriesPage />
        </PrivateRoute>
        <PrivateRoute exact path="/account" user={user}>
          <AccountPage
            user={user}
            displayName={displayName}
            signOut={signOut}
            setError={setError}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/my-tickets" user={user}>
          <TicketsPage
            user={user}
            displayName={displayName}
            signOut={signOut}
          />
        </PrivateRoute>
        <PrivateRoute exact path="/personal-information" user={user}>
          <PersonalInformation email={email} displayName={displayName} />
        </PrivateRoute>
        <PrivateRoute path="/events/:id" user={user}>
          <DetailPage />
        </PrivateRoute>
        <Route exact path="/las">
          <LasPage />
        </Route>
        <Route exact path="/verification">
          <VerficationPage
            user={user}
            error={error}
            setError={setError}
            email={email}
            password={password}
            handleChangeInput={handleChangeInput}
            signInWithEmailPassword={signInWithEmailPassword}
            signUpWithEmailPassword={signUpWithEmailPassword}
            signInWithGoogle={signInWithGoogle}
            signInWithFacebook={signInWithFacebook}
          />
        </Route>
        <Route exact path="/signup">
          <SingupPage
            user={user}
            error={error}
            email={email}
            password={password}
            signInWithGoogle={signInWithGoogle}
            signInWithFacebook={signInWithFacebook}
          />
        </Route>
      </Router>
    </div>
  )
}

export default App
