import React from 'react'

import './style.scss'

const ProgressBar = () => {
  return (
    <div className="progress-bar">
      <hr className="progress-bar__green" />
      <div className="progress-bar__check">
        <div className="progress-bar__check__tickets">
          <div>+0</div>
          <div>last row</div>
        </div>
      </div>
      <hr className="progress-bar__gray" />
      <div className="progress-bar__check">
        <div className="progress-bar__check__tickets progress-bar__check__tickets--disabled">
          <div>+100</div>
          <div>middle row</div>
        </div>
      </div>
      <hr className="progress-bar__gray" />
      <div className="progress-bar__check">
        <div className="progress-bar__check__tickets progress-bar__check__tickets--disabled">
          <div>+300</div>
          <div>VIP</div>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar


export const ProgressBarWithoutRows = () => {
  return (
    <div className="progress-bar">
      <hr className="progress-bar__greenNoRows" />
      <hr className="progress-bar__gray" />
      <div className="progress-bar__checkNoRows">
        <div className="progress-bar__checkNoRows__tickets progress-bar__check__tickets--disabled">
          <div>+20</div>
          <div>points</div>
        </div>
      </div>
    </div>
  )
}
