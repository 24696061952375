import { useState, useEffect } from 'react'

import { firebaseAuth, authProviders } from '../config/firebase'
import firebase from 'firebase/app'

const useFirebaseAuth = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('authUser')))
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const handleChangeInput = (evt) => {
    const { value, name } = evt.target

    if (name === 'email') setEmail(value)
    if (name === 'password') setPassword(value)
    return
  }

  const signUpWithEmailPassword = async (email, password, name) => {
    try {
      await firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

      const userCredential = await firebaseAuth.createUserWithEmailAndPassword(
        email,
        password
      )

      localStorage.setItem('displayName', JSON.stringify(name))

      const user = userCredential.user
      setUser(user)
      return user
    } catch (error) {
      setError(error)
      return error
    }
  }

  const verifyEmail = async () => {
    try {
      if (!user) return
      return user.sendEmailVerification()
    } catch (error) {
      return setError(error)
    }
  }

  const signInWithEmailPassword = async () => {
    try {
      await firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      const userCredential = await firebaseAuth.signInWithEmailAndPassword(
        email,
        password
      )
      const user = userCredential.user
      setUser(user)
      return user
    } catch (error) {
      setError(error)
      return error
    }
  }

  const signInWithGoogle = async () => {
    try {
      await firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      const { user } = await firebaseAuth.signInWithPopup(authProviders.google)
      setUser(user)
      return user
    } catch (error) {
      setError(error)
      return error
    }
  }

  const signInWithFacebook = async () => {
    try {
      await firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      const { user } = await firebaseAuth.signInWithPopup(
        authProviders.facebook
      )
      setUser(user)
      return user
    } catch (error) {
      const fbError = {
        message:
          'Sorry, you have used this email address before to sign in with Google provider. Please try to sign in with Google instead.',
      }
      setError(fbError)
      return error
    }
  }

  const signOut = () => firebaseAuth.signOut().then(() => setUser(null))

  useEffect(() => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem('authUser', JSON.stringify(user))
        setUser(user.providerData[0])

        user?.displayName &&
          localStorage.setItem('displayName', JSON.stringify(user?.displayName))
      }
    })

    return () => {
      localStorage.removeItem('authUser')
      setUser(null)
    }
  }, [])

  return {
    user,
    error,
    setError,
    email,
    password,
    handleChangeInput,
    signUpWithEmailPassword,
    verifyEmail,
    signInWithEmailPassword,
    signInWithGoogle,
    signInWithFacebook,
    signOut,
  }
}

export default useFirebaseAuth
