import React from 'react'
import DatePicker from 'react-date-picker/dist/entry.nostyle'

import Button from '../components/button'

 const AgeValidation = ({handleChange, handleConfirm, birthDate}) => (
  <div className="las__age-validation">
    <p>Age validation</p>
    <h2>What is your date of birth?</h2>
    <DatePicker
      format="dd-MM-yyyy"
      onChange={handleChange}
      value={birthDate}
      disableCalendar={true}
      showLeadingZeros={true}
      clearIcon={null}
      dayPlaceholder="DD"
      monthPlaceholder="MM"
      yearPlaceholder="YYYY"
    />
    <div className="las__btn-wrapper">
      <Button
        classMod="primary"
        text="confirm"
        disabled={!birthDate}
        handleClick={handleConfirm}
      />
    </div>
  </div>
)

export default AgeValidation