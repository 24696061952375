import RenderInputs from '../components/render-inputs'
import Button from '../components/button'
import { ErrorMsg } from '../components/popup-message'

const SignUpInputs = ({
  name,
  inputValues,
  label,
  title,
  placeholder,
  options,
  clickPrevious,
  clickNext,
  handleChangeInput,
  error,
}) => {
  const backBtnText =
    name === 'email' ? '< overview' : name === 'verifyEmail' ? '' : '< back'

  const nextBtnText =
    name === 'storeType'
      ? 'Create account'
      : name === 'verifyEmail'
      ? 'Go to mail'
      : 'Next'

  return (
    <>
      <div className="signup__label" onClick={clickPrevious}>
        {backBtnText}
      </div>
      <h2 className={`signup__title signup__title--${name}`}>{title}</h2>
      {name === 'verifyEmail' ? (
        <p>
          You’ve created a new account! All we need is a confirmation from your
          email address and your good to go. <br/>
          <br /> We already added your first points to your account.
        </p>
      ) : (
        <p>{label}</p>
      )}
      <RenderInputs
        name={name}
        inputValues={inputValues}
        options={options}
        placeholder={placeholder}
        handleChangeInput={handleChangeInput}
      />
      <ErrorMsg error={error} />
      <div
        className={`signup__next-btnWrapper signup__next-btnWrapper--${name}`}
      >
        <Button
          disabled={nextBtnText !== 'Go to mail' && !inputValues[name]}
          handleClick={clickNext}
          classMod="primary"
          text={nextBtnText}
        />
      </div>
    </>
  )
}

export default SignUpInputs
