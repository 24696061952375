import React from 'react'
import propTypes from 'prop-types'

import Icon from '../icon'
import './style.scss'

const PointsTag = ({ points }) => {
  return (
    <div className='points-tag'>
      <div className='points-tag__value'>+{points}</div>
      <Icon name='points' size={30} />
    </div>
  )
}

PointsTag.propTypes = {
  points: propTypes.number.isRequired,
}

export default PointsTag
