import React from "react";
import propTypes from "prop-types";

import useCarousel from "../../lib/useCarousel";
import EventCard from "../event-card";
import Icon from "../icon";
import "./style.scss";

const Carousel = ({ title, cards }) => {
  const eventCards = cards.map(({ id, points, image,bannerImage, title, text }, i) => {
    return (
      <EventCard
        id={id}
        points={points}
        image={image}
        title={title}
        text={text}
        key={i}
      />
    );
  });

  const [activeCards, handleClick] = useCarousel(2, eventCards);

  return (
    <div className="carousel">
      <div className="carousel__header">
        <h4>{title}</h4>
        <div className="carousel__buttons-wrapper">
          <Icon name="arrow-left" size={20} handleClick={handleClick("left")} />
          <Icon
            name="arrow-right"
            size={20}
            handleClick={handleClick("right")}
          />
        </div>
      </div>
      <div className="carousel__card-wrapper">
        {activeCards.map((card, i) => {
          return <div key={i}>{card}</div>;
        })}
      </div>
    </div>
  );
};

Carousel.propTypes = {
  title: propTypes.string.isRequired,
  cards: propTypes.array.isRequired,
};

export default Carousel;
