import React from 'react'

import TopBar from '../layout/top-bar'
import Footer from '../layout/footer'
import Main from '../layout/main'
import Header from '../components/header'
import Icon from '../components/icon'

export default function TicketsPage({ user, signOut }) {
  return (
    <>
      <TopBar />
      <Main>
        <Header title="My tickets" small />
        <section className="tickets">
          <div className="tickets__item">
            <div className="tickets__item--myTickets">
              <h5>1x online set by dj one</h5>
              <div className="tickets__item--myTickets__points">
                <h5>340</h5>
                <Icon name="points-blue" size={32} />
              </div>
            </div>
            <p>Last row tickets</p>
          </div>
          <div className="tickets__item">
            <div className="tickets__item--myTickets">
              <h5>ra boiler room</h5>
              <div className="tickets__item--myTickets__points">
                <h5>200</h5>
                <Icon name="points-blue" size={32} />
              </div>
            </div>
            <p>Last row tickets</p>
          </div>
        </section>
      </Main>
      <Footer />
    </>
  )
}
