import React from 'react'
import { useHistory } from 'react-router-dom'

import TopBar from '../layout/top-bar'
import Main from '../layout/main'
import Button from '../components/button'
import useSignupForm from '../lib/useSignupForm'
import { signUpProps } from '../data/signup'
import SignUpInputs from '../elements/signupElems'

const SignupPage = ({ signInWithGoogle, signInWithFacebook }) => {
  const history = useHistory()

  const {
    inputValues,
    activeCard,
    handleChangeInput,
    clickNext,
    clickPrevious,
    formError,
  } = useSignupForm(signUpProps, history)

  const signUpwithFB = () =>
    signInWithFacebook()
      .then(() => history.push('/'))
      .catch((err) => console.log(`error`, err))

  const signUpwithGoogle = () =>
    signInWithGoogle()
      .then(() => history.push('/'))
      .catch((err) => console.log(`error`, err))

  const { name, label, title, placeholder, options } = activeCard

  return (
    <>
      <TopBar />
      <Main>
        <section className="signup">
          <SignUpInputs
            name={name}
            inputValues={inputValues}
            label={label}
            title={title}
            placeholder={placeholder}
            options={options}
            clickPrevious={clickPrevious}
            clickNext={clickNext}
            handleChangeInput={handleChangeInput}
            error={formError}
          />
          {name === 'email' && (
            <div className="signup__firebaseWrapper">
              <h4>Or quickly sign up</h4>
              <Button
                handleClick={signUpwithFB}
                classMod="primary"
                text="Sign up with Facebook"
              />
              <Button
                handleClick={signUpwithGoogle}
                classMod="primary"
                text="Sign up with Google"
              />
            </div>
          )}
          {name === 'storeType' && (
            <p className="signup__privacyAgreement">
              By clicking ‘Create account’, you agree to our Terms. Learn how we
              collect, use and share your data in our Data Policy.
            </p>
          )}
        </section>
      </Main>
    </>
  )
}

export default SignupPage
