export const homePageProps = [
  {
    title: 'Use your points now for exclusive access to:',
    cards: [
      {
        id: 'coachella',
        points: 100,
        image: '/images/coachella-small.png',
        title: 'coachella',
        text: 'Get access to this iconic festival now.',
      },
      {
        id: 'o-beach-ibiza',
        points: 200,
        image: '/images/ibiza-small.png',
        title: 'O Beach Ibiza',
        text: 'A poolparty you will never forget.',
      },
      {
        id: 'light-museum',
        points: 300,
        image: '/images/light-museum-small.png',
        title: 'Light Museum',
        text: 'Light + art = magic!',
      },
      {
        id: 'rijksmuseum',
        points: 400,
        image: '/images/rijksmuseum-small.png',
        title: 'Rijksmuseum',
        text: 'Wanna see a real Rembrandt?',
      },
    ],
  },
  {
    title: 'Popular Events',
    cards: [
      {
        id: 'boiler-room',
        points: 560,
        image: '/images/boiler-room-small.png',
        title: 'RA boiler room',
        text: 'A once-in-a-lifetime opportunity.',
      },
      {
        id: 'melkweg',
        points: 340,
        image: '/images/melkweg-small.png',
        title: 'Melkweg events',
        text: 'Ready for a concert? We got you!',
      },
      {
        id: 'light-museum',
        points: 300,
        image: '/images/light-museum-small.png',
        title: 'Light Museum',
        text: 'Light + art = magic!',
      },
      {
        id: 'rijksmuseum',
        points: 400,
        image: '/images/rijksmuseum-small.png',
        title: 'Rijksmuseum',
        text: 'Wanna see a real Rembrandt?',
      },
    ],
  },
]
