import React from 'react'

import TopBar from './top-bar/index'
import Main from './main/index'
import Footer from './footer/index'

const PageLayout = ({ withFooter = true, children }) => {
  return (
    <>
      <TopBar />
      <Main>{children}</Main>
      {withFooter && <Footer />}
    </>
  )
}

export default PageLayout
