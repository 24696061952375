import { useState } from "react";

function useCarousel(numOfCards, cards) {
  const [activeCards, setActiveCards] = useState(cards.slice(0, numOfCards));
  //  activeRange represents the active images indexes range
  //  EX: activeRange = [0,4] means that the images which their index falls into
  //  this range will be displayed
  const [activeRange, setActiveRange] = useState([0, numOfCards]);

  const handleClick = (direction) => (evt) => {
    let newActiveRange;
    let newActiveImgs;

    if (direction === "right") {
      // This variable holds the new indexes range based on the current state
      newActiveRange =
        activeRange[1] < cards.length
          ? [activeRange[1], activeRange[1] + numOfCards]
          : [0, numOfCards];

      // This variable holds the new images based on the current state
      newActiveImgs = cards.filter(
        (img, index) => index < newActiveRange[1] && index >= newActiveRange[0]
      );
    }

    if (direction === "left") {
      newActiveRange =
        activeRange[0] - numOfCards >= 0
          ? [activeRange[0] - numOfCards, activeRange[0]]
          : [cards.length - numOfCards, cards.length];

      newActiveImgs = cards.filter((card, index) => 
         (index < newActiveRange[1] && index >= newActiveRange[0])
      );
    }

    setActiveRange(newActiveRange);
    setActiveCards(newActiveImgs);
  };

  return [activeCards, handleClick];
}

export default useCarousel;
