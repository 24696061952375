export const signUpProps = [
  {
    name: 'email',
    title: 'Set up your account',
    label: '1 / 5 What is your email address?*',
    placeholder: 'Your email address',
    options: null,
  },
  {
    name: 'name',
    title: 'Set up your account',
    label: '2 / 5 Alright, and what is your name?*',
    placeholder: 'Your name',
    options: null,
  },
  {
    name: 'password',
    title: 'Set up your account',
    label: '3 / 5 Thank you. Please create a secure password*',
    placeholder: '*******',
    options: null,
  },
  {
    name: 'country',
    label: '4 / 5 Great! Now please select your country*',
    title: 'Set up your account',
    placeholder: null,
    options: ['The Netherlands', 'Belgium', 'Switzerland'],
  },
  {
    name: 'storeType',
    title: 'Set up your account',
    label:
      '5 / 5 The last question. Where did you purchase your last scanned item?*',
    placeholder: null,
    options: [
      'Supermarket',
      'Specialty tobacco store',
      'Gas station',
      'Online',
    ],
  },
  {
    name: 'verifyEmail',
    title: 'Verify your email address',
    label:
      'You’ve created a new account! All we need is a confirmation from your email address and your good to go. \n We already added your first points to your account.',
    placeholder: null,
    options: null,
  },
]

export const addToFirebaseProps = [
  {
    name: 'password',
    title: 'Additions to your account',
    label: '2 / 3 Thank you John, Please create a secure password*',
    placeholder: '',
    options: null,
  },
  {
    name: 'storeType',
    title: 'Additions to your account',
    label:
      '3 / 3 The last question. Where did you purchase your last scanned item?*',
    placeholder: null,
    options: [
      'Supermarket',
      'Specialty tobacco store',
      'Gas station',
      'Online',
    ],
  },
  {
    name: 'verifyEmail',
    title: 'You have created a new account',
    label: 'We already added your first points to your account!',
    placeholder: null,
    options: null,
  },
]
