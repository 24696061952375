import React from 'react'
import propTypes from 'prop-types'

import PointsTag from '../points-tag'
import Icon from '../icon'
import './style.scss'

const Header = ({ title, points, addPoints, small = false }) => {
  return (
    <div className="header">
      <h3 className={`header__title ${!!small && 'header__title--small'}`}>
        {title}
      </h3>
      {points && (
        <div
          className={`header__points ${
            addPoints && 'header__points--add-points'
          }`}
        >
          <h1>{points}</h1>
          <Icon name="points-blue" size={40} />
        </div>
      )}
      {addPoints && <PointsTag points={addPoints} />}
    </div>
  )
}

Header.propTypes = {
  title: propTypes.string.isRequired,
  points: propTypes.number,
  addPoints: propTypes.number,
  small: propTypes.bool,
}

export default Header
