import { useHistory } from 'react-router-dom'

import Input from '../components/input'
import Button from '../components/button'
import { ErrorMsg } from '../components/popup-message'

export const SignOutButton = ({ user, setError, signOut }) => {
  const history = useHistory()

  const handleSignOut = () =>
    signOut().then(() => {
      localStorage.removeItem('authUser')
      setError(null)
      return history.push('/las')
    })

  if (!user) return null
  return <button onClick={handleSignOut}>Sign out</button>
}

const SignInInputs = ({
  user,
  error,
  email,
  password,
  redirectToSignUp,
  handleChangeInput,
  signInWithEmailPassword,
}) => (
  <div>
    <div className="signin__inputsWrapper">
      <Input
        type="email"
        name="email"
        placeholder="Your email address"
        value={email}
        handleChange={handleChangeInput}
      />
      <Input
        type="password"
        name="password"
        placeholder="Your password"
        value={password}
        handleChange={handleChangeInput}
      />
    </div>
    <ErrorMsg error={error} />
    <div className="signin__btnsWrapper">
      <Button
        disabled={!user && !password}
        handleClick={signInWithEmailPassword}
        text="Next"
        classMod="primary"
      />
      <button onClick={redirectToSignUp}>Create account</button>
    </div>
  </div>
)

export default SignInInputs
