import React from 'react'

import Icon from '../icon'
import './style.scss'

export const ErrorMsg = ({ error }) => (
  <>
    {error && (
      <div className="error__container">
        <p className="error__msg"> {error.message}</p>
      </div>
    )}
  </>
)

const PopupMessage = ({ type, message, handleClick }) => {
  const color = type === 'error' ? '#f03d3e' : '#36E3AF'
  const icon = type === 'notification' ? 'bell' : type

  return (
    <div className={`popup-message ${type}`}>
      <div className="popup-message__wrapper">
        <Icon name={icon} color={color} />
        <p>
          {type}: {message}
        </p>
      </div>
      <Icon name="cancel" color="#000" size={16} handleClick={handleClick} />
    </div>
  )
}

export default PopupMessage
