import { useState } from 'react'

import { firebaseAuth } from '../config/firebase'
import firebase from 'firebase/app'

const useSignupForm = (cards, history) => {
  const [activeCard, setActiveCard] = useState(cards[0])
  const [inputValues, setInputValues] = useState({
    email: '',
    name: '',
    password: '',
    country: 'The Netherlands',
    storeType: 'Supermarket',
  })

  const [formError, setFormError] = useState(null)
  const [registeredUser, setRegisteredUser] = useState(null)

  const cardIndex = cards.indexOf(activeCard)

  const handleChangeInput = (evt) => {
    setInputValues((prevState) => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }))
  }

  const clickPrevious = () => {
    if (activeCard === cards[0]) {
      history.push('/verification')
    } else {
      setActiveCard(cards[cardIndex - 1])
      setFormError(null)
    }
  }

  const signUpWithEmailPassword = async (email, password, name) => {
    try {
      await firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      const userCredential = await firebaseAuth.createUserWithEmailAndPassword(
        email,
        password
      )

      localStorage.setItem('authUser', JSON.stringify(userCredential.user))
      localStorage.setItem('displayName', JSON.stringify(name))

      return userCredential.user
    } catch (error) {
      setFormError(error)
    }
  }

  const verifyEmail = async (user) => {
    try {
      if (!user) return
      return user.sendEmailVerification()
    } catch (error) {
      return setFormError(error)
    }
  }

  const clickNext = () => {
    if (activeCard === cards[cards.length - 1]) {
      verifyEmail(registeredUser)
        .then(() => history.push('/'))
        .catch((err) => setFormError(err))
    } else if (activeCard === cards[cards.length - 2]) {
      const { email, password, name } = inputValues

      signUpWithEmailPassword(email, password, name)
        .then((user) => {
          setRegisteredUser(user)
          if (user) setActiveCard(cards[cardIndex + 1])
        })
        .catch((err) => setFormError(err))
    } else {
      setActiveCard(cards[cardIndex + 1])
    }
  }

  return {
    inputValues,
    activeCard,
    handleChangeInput,
    clickNext,
    clickPrevious,
    formError,
  }
}

export default useSignupForm
