import React from 'react'

import Header from '../components/header'
import Carousel from '../components/carousel'
import TopBar from '../layout/top-bar'
import Footer from '../layout/footer'
import Main from '../layout/main'
import { categoriesPageProps } from '../data/categories'

export default function CategoriesPage() {
  // temp hardcoded
  const headerProps = {
    title: 'Categories',
    points: 200,
  }

  return (
    <>
      <TopBar />
      <Main>
        <Header
          title={headerProps.title}
          points={headerProps.points}
          addPoints={headerProps.addPoints}
        />
        <section className="categories-wrapper">
          {categoriesPageProps.map(({ title, cards }, i) => {
            return <Carousel key={i} title={title} cards={cards} />
          })}
        </section>
      </Main>
      <Footer />
    </>
  )
}
