import React from "react";
import propTypes from "prop-types";

import Icon from "../icon";
import "./style.scss";

const Banner = ({ points, image, title }) => {
  return (
    <div className="banner">
      <img src={image} alt="" />
      <h3 className="banner__title">{title}</h3>
      <div className="banner__points">
        <h5>{points}</h5>
        <Icon name="points" size={30} />
      </div>
    </div>
  );
};

Banner.propTypes = {
  points: propTypes.number.isRequired,
  image: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
};

export default Banner;
