import React, { useState } from "react";
import propTypes from "prop-types";

import "./style.scss";

const AmountSelector = ({ minValue, maxValue, initlValue }) => {
  const [value, setValue] = useState(initlValue || 0);

  const limitMin = value > (minValue || 0);
  const limitMax = value < maxValue;

  const decrementValue = () => {
    if (limitMin) setValue(value - 1);
  };

  const incrementValue = () => {
    if (limitMax) setValue(value + 1);
  };

  return (
    <div className="amount-selector">
      <div
        className={`amount-selector__plusminus ${
          !limitMin && "amount-selector__plusminus--disabled"
        }`}
        onClick={decrementValue}
      >
        -
      </div>
      <p className="amount-selector__value">{value}</p>
      <div
        className={`amount-selector__plusminus ${
          !limitMax && "amount-selector__plusminus--disabled"
        }`}
        onClick={incrementValue}
      >
        +
      </div>
    </div>
  );
};

AmountSelector.propTypes = {
  minValue: propTypes.number,
  maxValue: propTypes.number,
  initlValue: propTypes.number,
};

export default AmountSelector;
