import React from 'react'

import Icon from '../../components/icon/index'
import './style.scss'

const TopBar = () => {
  return (
    <div className="top-bar">
      <Icon name="topBarIcon" />
    </div>
  )
}

export default TopBar
