import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import TopBar from '../layout/top-bar'
import Main from '../layout/main'
import verifyAge from '../lib/verifyAge'
import AgeValidation from '../elements/ageValidElems'
import { ErrorMsg } from '../components/popup-message/index'

export default function LasPage() {
  const [birthDate, setBirthDate] = useState(null)
  const [isAdult, setAdult] = useState(null)

  const handleChangeDate = (date) => setBirthDate(date)
  const handleConfirm = () => setAdult(verifyAge(birthDate))

  const errorMsg = {
    message : "You must be 18 years old or above"
  }

  return (
    <>
      <TopBar />
      <Main>
        <section className="las">
          <AgeValidation
            handleChange={handleChangeDate}
            handleConfirm={handleConfirm}
            birthDate={birthDate}
          />
          {!!isAdult && <Redirect to={'/verification'} />}
          {isAdult === false && <ErrorMsg error={errorMsg} />}
          <p>
            Our products contain nicotine and are intended for use by existing
            smokers or vapers aged 18 or over. More information can be found
            here.
          </p>
        </section>
      </Main>
    </>
  )
}
