import React from 'react'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types'

import Icon from '../icon'
import './style.scss'

const EventCard = ({ id, points, image, title, text }) => {
  return (
    <Link to={`/events/${id}`}>
      <div className="event-card">
        <div className="event-card__image-wrapper">
          <img src={image} alt="" />
          <div className="event-card__points">
            <h5>{points}</h5>
            <Icon name="points" />
          </div>
        </div>
        <p className="event-card__title">{title}</p>
        <p className="event-card__text">{text}</p>
      </div>
    </Link>
  )
}

EventCard.propTypes = {
  points: propTypes.number.isRequired,
  image: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
}

export default EventCard
