import React from "react";

import TopBar from "../layout/top-bar";
import Footer from "../layout/footer";
import Main from "../layout/main";
import Header from "../components/header";
import Button from "../components/button";

const PersonalInformation = ({ displayName, email }) => {
  return (
    <>
      <TopBar />
      <Main>
        <Header title="Personal information" small />
        <section className="personal-info">
          <div className="personal-info__item">
            <div>
              <p>Name</p>
              <p>{displayName}</p>
            </div>
            <div>
              <p>Email address</p>
              <p>{email}</p>
            </div>
            <div>
              <p>Birthdate</p>
              <p>12-06-1990</p>
            </div>
            <div>
              <p>Country</p>
              <p>Belgium</p>
            </div>
            <div className="personal-info__item--btnWrapper">
              <Button
                classMod="alternate"
                text="Edit"
                handleClick={() => console.log("EDIT INFO")}
              />
            </div>
          </div>
          <div className="personal-info__connect">
            <div className="personal-info__connect--fragment">
              <p>Facebook</p>
              <Button
                classMod="alternate"
                text="Connect"
                handleClick={() => console.log("CONNECT TO FACEBOOK")}
              />
            </div>
            <div className="personal-info__connect--fragment">
              <p>Google</p>
              <Button
                classMod="alternate"
                text="Connect"
                handleClick={() => console.log("CONNECT TO GOOGLE")}
              />
            </div>
          </div>
        </section>
      </Main>
      <Footer />
    </>
  );
};

export default PersonalInformation;
