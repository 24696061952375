import React, { useState } from 'react'
import propTypes from 'prop-types'

import Icon from '../icon'
import './style.scss'

const Input = ({
  name,
  value,
  type,
  label,
  placeholder,
  icon = '',
  iconColor = '',
  handleChange,
}) => {
  const [displayPassword, setDisplayPassword] = useState(false)

  const togglePassword = () => {
    setDisplayPassword(!displayPassword)
  }

  if (type === 'password') {
    return (
      <div className="input">
        {label && <label>{label}</label>}
        <div className="input__container">
          <input
            name={name}
            type={displayPassword ? 'text' : 'password'}
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
          />
          <Icon
            name={displayPassword ? 'eye' : 'eye-off'}
            handleClick={togglePassword}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="input">
      {label && <label>{label}</label>}
      <div className="input__container">
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
        />
        <Icon name={icon} color={iconColor} />
      </div>
    </div>
  )
}

Input.propTypes = {
  name: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  label: propTypes.string,
  placeholder: propTypes.string,
  icon: propTypes.string,
  iconColor: propTypes.string,
  handleChange: propTypes.func.isRequired,
}

export default Input
