import React from 'react'
import { useHistory } from 'react-router-dom'

import TopBar from '../layout/top-bar'
import Footer from '../layout/footer'
import Main from '../layout/main'
import Header from '../components/header'
import Carousel from '../components/carousel'
import Button from '../components/button'
import { homePageProps } from '../data/homepage'

export default function HomePage({ user }) {
  const history = useHistory()
  const displayName = localStorage.getItem('displayName')
    ? JSON.parse(localStorage.getItem('displayName')).split(' ')[0]
    : ''

  const handleClick = () => {
    history.push('/categories')
  }

  // temp hardcoded
  const headerProps = {
    title: `Welcome to the Innovate shop, ${displayName}!`,
    points: 200,
    addPoints: 30,
  }

  return (
    <>
      <TopBar />
      <Main>
        <Header
          title={headerProps.title}
          points={headerProps.points}
          addPoints={headerProps.addPoints}
        />
        <section className="homepage-wrapper">
          {homePageProps.map(({ title, cards }, i) => {
            return <Carousel key={i} title={title} cards={cards} />
          })}
          <Button
            handleClick={handleClick}
            classMod="primary"
            text="All events"
          />
        </section>
      </Main>
      <Footer />
    </>
  )
}
