import React from 'react'

import Input from '../input'
import Select from '../select'

const RenderInputs = ({
  name,
  inputValues,
  options,
  placeholder,
  handleChangeInput,
}) => {
  if (name === 'email') {
    return (
      <Input
        type={'text'}
        name={name}
        value={inputValues.email}
        placeholder={placeholder}
        handleChange={handleChangeInput}
      />
    )
  } else if (name === 'name') {
    return (
      <Input
        type={'text'}
        name={name}
        value={inputValues.name}
        placeholder={placeholder}
        handleChange={handleChangeInput}
      />
    )
  } else if (name === 'password') {
    return (
      <Input
        type={'password'}
        name={name}
        value={inputValues.password}
        placeholder={placeholder}
        handleChange={handleChangeInput}
      />
    )
  } else if (name === 'country') {
    return (
      <Select
        name={name}
        value={inputValues.country}
        options={options}
        defaultvalue={inputValues.country}
        handleChange={handleChangeInput}
      />
    )
  } else if (name === 'storeType') {
    return (
      <Select
        name={name}
        value={inputValues.storeType}
        options={options}
        defaultvalue={inputValues.storeType}
        handleChange={handleChangeInput}
      />
    )
  } else {
    return <></>
  }
}

export default RenderInputs
