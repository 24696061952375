import React from "react";
import { useHistory } from "react-router-dom";

import TopBar from "../layout/top-bar";
import Footer from "../layout/footer";
import Main from "../layout/main";
import Header from "../components/header";
import { SignOutButton } from "../elements/signinElems";

export default function AccountPage({ user, setError, signOut }) {
  const history = useHistory();

  return (
    <>
      <TopBar />
      <Main>
        <Header title="My account" small />
        <section className="account">
          <div>
            <div
              className="account__item"
              onClick={() => history.push("/my-tickets")}
            >
              <h4>My tickets</h4>
            </div>
            <div
              className="account__item"
              onClick={() => history.push("/personal-information")}
            >
              <h4>Personal information</h4>
            </div>
            <div className="account__item">
              <h4>About Innovate</h4>
            </div>
          </div>
          <SignOutButton user={user} setError={setError} signOut={signOut} />
        </section>
      </Main>
      <Footer />
    </>
  );
}
