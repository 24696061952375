import React from 'react'

import PageLayout from '../layout/index'
import Icon from '../components/icon/index'
import SigninPage from '../pages/signin'

const VerficationContent = () => (
  <div className="verificationPage__content">
    <h4>Please verify your purchase below and get ready to party!</h4>
    <img
      src="images/Authentication_check_blue.png"
      alt="Authentication check blue"
      width={200}
      height={200}
    />
    <h1>Your product is AUTHENTHIC! 🎉</h1>
    <h4>Save your points to get access to exclusive events</h4>
    <a href="#signIn">
      <Icon name="icon-button" size={44} />
    </a>
  </div>
)

export default function VerificationPage({
  user,
  error,
  setError,
  email,
  password,
  handleChangeInput,
  signInWithEmailPassword,
  signUpWithEmailPassword,
  signInWithGoogle,
  signInWithFacebook,
}) {
  return (
    <PageLayout withFooter={false}>
      <section className="verificationPage">
        <VerficationContent />
        <SigninPage
          user={user}
          error={error}
          setError={setError}
          email={email}
          password={password}
          handleChangeInput={handleChangeInput}
          signInWithEmailPassword={signInWithEmailPassword}
          signUpWithEmailPassword={signUpWithEmailPassword}
          signInWithGoogle={signInWithGoogle}
          signInWithFacebook={signInWithFacebook}
        />
      </section>
    </PageLayout>
  )
}

VerificationPage.prototype = {}
