import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'

import Icon from '../icon'
import './style.scss'

const Select = ({ name, label, options, defaultvalue, handleChange }) => {
  const [selectOption, setSelectOption] = useState(defaultvalue)
  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    setIsActive(!isActive)
  }

  const onOptionClick = (value) => (evt) => {
    handleChange(evt)
    setSelectOption(value)
    setIsActive(false)
  }

  useEffect(() => {
    setSelectOption(defaultvalue)

    return () => setSelectOption(null)
  }, [defaultvalue])

  return (
    <div className="select">
      {label && <label>{label}</label>}
      <div className="select__header" onClick={handleClick}>
        <div>{selectOption}</div>
        <Icon name="arrow-down" size={16} />
      </div>
      {isActive && (
        <div className="select__list">
          <ul>
            {options.map((option, i) => {
              return (
                <button
                  key={i}
                  name={name}
                  value={option}
                  onClick={onOptionClick(option)}
                >
                  {option}
                </button>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

Select.propTypes = {
  label: propTypes.string,
  options: propTypes.array.isRequired,
  defaultvalue: propTypes.string,
}

export default Select
