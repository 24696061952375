import React from "react";
import propTypes from "prop-types";

import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as BarChart } from "../../assets/icons/bar_chart.svg";
import { ReactComponent as Bell } from "../../assets/icons/bell.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";
import { ReactComponent as Cart } from "../../assets/icons/cart.svg";
import { ReactComponent as Compass } from "../../assets/icons/compass.svg";
import { ReactComponent as CreditCard } from "../../assets/icons/credit_card.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Error } from "../../assets/icons/error.svg";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeOff } from "../../assets/icons/eye-off.svg";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { ReactComponent as Help } from "../../assets/icons/help.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Image } from "../../assets/icons/image.svg";
import { ReactComponent as Inbox } from "../../assets/icons/inbox.svg";
import { ReactComponent as Map } from "../../assets/icons/map.svg";
import { ReactComponent as Points } from "../../assets/icons/points.svg";
import { ReactComponent as PointsBlue } from "../../assets/icons/points-blue.svg";
import { ReactComponent as PointsGreen } from "../../assets/icons/points-green.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as Star } from "../../assets/icons/star.svg";
import { ReactComponent as Success } from "../../assets/icons/success.svg";
import { ReactComponent as Upload } from "../../assets/icons/upload.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Users } from "../../assets/icons/users.svg";
import { ReactComponent as Zap } from "../../assets/icons/zap.svg";
import { ReactComponent as IconButton } from "../../assets/icons/icon_button.svg";
import { ReactComponent as HowItWorksImg } from "../../assets/icons/How_it_works.svg";
import { ReactComponent as TopBarIcon } from "../../assets/icons/Innovate-logo.svg";
import "./style.scss";

const icons = {
  "arrow-down": <ArrowDown />,
  "arrow-left": <ArrowLeft />,
  "arrow-right": <ArrowRight />,
  "bar-chart": <BarChart />,
  bell: <Bell />,
  calendar: <Calendar />,
  cancel: <Cancel />,
  cart: <Cart />,
  compass: <Compass />,
  "credit-card": <CreditCard />,
  download: <Download />,
  edit: <Edit />,
  error: <Error />,
  eye: <Eye />,
  "eye-off": <EyeOff />,
  filter: <Filter />,
  help: <Help />,
  home: <Home />,
  image: <Image />,
  inbox: <Inbox />,
  map: <Map />,
  points: <Points />,
  "points-blue": <PointsBlue />,
  "points-green": <PointsGreen />,
  search: <Search />,
  share: <Share />,
  star: <Star />,
  success: <Success />,
  upload: <Upload />,
  user: <User />,
  users: <Users />,
  zap: <Zap />,
  "icon-button": <IconButton />,
  howItWorksImg: <HowItWorksImg />,
  topBarIcon: <TopBarIcon/>
};

const Icon = ({ name, size = 24, color, handleClick }) => {
  return (
    <div
      onClick={handleClick}
      className={`icon ${color && "icon--colored"}`}
      style={{ color, width: size, height: size }}
    >
      {icons[name]}
    </div>
  );
};

Icon.propTypes = {
  name: propTypes.string.isRequired,
  size: propTypes.number,
  color: propTypes.string,
  handleClick: propTypes.func,
};

export default Icon;
