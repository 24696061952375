import React from 'react'
import propTypes from 'prop-types'

import Icon from '../icon'
import './style.scss'

const FooterItem = ({ text, icon, slug, handleClick, active }) => {
  const color = active ? '#2A1FFF' : ''

  return (
    <div
      className={`footer-item ${active && 'footer-item--active'}`}
      onClick={(e) => {
        handleClick(slug, e)
      }}
    >
      <Icon name={icon} color={color} />
      <p>{text}</p>
    </div>
  )
}

FooterItem.propTypes = {
  text: propTypes.string.isRequired,
  icon: propTypes.string.isRequired,
  slug: propTypes.string.isRequired,
  i: propTypes.number.isRequired,
  handleClick: propTypes.func.isRequired,
  active: propTypes.bool.isRequired,
}

export default FooterItem
