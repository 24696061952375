import React from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import SignInInputs from '../elements/signinElems'
import Button from '../components/button'
import Icon from '../components/icon/index'

const HowItWorks = () => (
  <div className="signin__how-it-works">
    <h4>How it works</h4>
    <div className="how-it-works-icon">
      <Icon name="howItWorksImg" />
    </div>
    <p>Scan, collect points</p>
    <p>& get accessto exclusive events!</p>
    <p>Create an account to earn points with every new scan.</p>
  </div>
)

const SigninPage = ({
  user,
  error,
  setError,
  email,
  password,
  handleChangeInput,
  signInWithEmailPassword,
  signInWithGoogle,
  signInWithFacebook,
}) => {
  const history = useHistory()
  const lastLocation = useLocation()
  const pathname = lastLocation?.state?.from?.pathname ?? '/'

  const redirectToSignUp = () => {
    history.push('/signup')
    setError(null)
  }

  return (
    <div className="signin" id="signIn">
      {!user ? (
        <>
          <h1>Innovate’s exclusive events</h1>
          <p>Log in to save your points.</p>
          <SignInInputs
            user={user}
            email={email}
            password={password}
            error={error}
            redirectToSignUp={redirectToSignUp}
            handleChangeInput={handleChangeInput}
            signInWithEmailPassword={signInWithEmailPassword}
          />
          <div className="signin__social-btnsWrapper">
            <Button
              classMod="primary"
              handleClick={signInWithFacebook}
              text="Sign in with Facebook"
            />
            <Button
              classMod="primary"
              handleClick={signInWithGoogle}
              text="Sign in with Google"
            />
          </div>
          <HowItWorks />
        </>
      ) : (
        <Redirect to={`${pathname}`} />
      )}
    </div>
  )
}

export default SigninPage
